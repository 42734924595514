body {
  margin: 0;
  font-family: Garamond, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16pt;
  color: #333;
  background-color: #f7f7f7;
}

a {
  color: #003683;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #0076c3;
}

a.inverted {
  color: #f7f7f7;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

a:hover.inverted {
  text-decoration: underline;
  transition: cubic-bezier(1, 0, 0, 1)
}

h1 {
  font-size: 40pt;
  font-weight: normal;
}

h2 {
  font-size: 24pt;
  font-weight: normal;
}